import { AppBar, SelectChangeEvent, Toolbar, Typography } from "@mui/material";

interface NavbarProps {
    organisation: string;
    onChange: (event: SelectChangeEvent<string>) => void;
}

export function Navbar({ organisation, onChange }: NavbarProps) {
    return (
        <div className="App">
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" style={{ flex: 1 }}>
                        💈 Barberstats
                    </Typography>

                    {/* <Select
                        value={organisation}
                        onChange={onChange}
                        displayEmpty
                        style={{ color: "white" }}
                    >
                        <MenuItem value="labbs">LABBS</MenuItem>
                        <MenuItem value="babs">BABS</MenuItem>
                    </Select> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}
