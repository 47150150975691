type ScoreType = "Quartet" | "Chorus";

export class Score {
    public Year: string;
    public Contest: string;
    public Contestant: string;
    public Song: string;
    public Mus: number;
    public Pres: number;
    public Sing: number;

    constructor(
        year: string,
        contest: string,
        contestant: string,
        song: string,
        mus: number,
        pres: number,
        sing: number
    ) {
        this.Year = year;
        this.Contest = contest;
        this.Contestant = contestant;
        this.Song = song;
        this.Mus = mus;
        this.Pres = pres;
        this.Sing = sing;
    }

    get Name(): string {
        return this.Contestant.split(" (")[0];
    }

    get ScoreType(): ScoreType {
        return this.Contest && this.Contest.includes("Quartet")
            ? "Quartet"
            : "Chorus";
    }

    get Director(): string {
        const match = this.Contestant.match(/\(([^)]+)\)/);
        return match ? match[1] : "";
    }

    get ChorusSize(): number {
        const match = this.Contestant.match(/\((\d+)\)$/);
        return match ? parseInt(match[1]) : 0;
    }

    get Members(): string[] {
        const pattern = /\((.*?)\)/;
        const match = this.Contestant.match(pattern);
        return match ? match[1].split(", ") : [];
    }

    get Total(): number {
        return ((this.Mus + this.Pres + this.Sing) / 900) * 100;
    }
}
