import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import { App } from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <HashRouter>
        <App />
    </HashRouter>
);

reportWebVitals();