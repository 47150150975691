import { SelectChangeEvent } from "@mui/material";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";

import { Navbar } from "./components/Navbar";
import { BarGraph } from "./graphs/BarGraph";
import { Score } from "./types";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export function App() {
    const [organisation, setOrganisation] = useState("labbs");
    const [scores, setScores] = useState<Score[]>([]);

    useEffect(() => {
        import(`./data/${organisation}.json`)
            .then((data) =>
                setScores(
                    data.default.map(
                        (s: any) =>
                            new Score(
                                s.Year,
                                s.Contest,
                                s.Contestant,
                                s.Song,
                                s.Mus,
                                s.Pres,
                                s.Sing
                            )
                    )
                )
            )
            .catch((error) =>
                console.error(`Failed to load data for ${organisation}`, error)
            );
    }, [organisation]);

    return (
        <div className="App">
            <Navbar
                organisation={organisation}
                onChange={(e: SelectChangeEvent<string>) =>
                    setOrganisation(e.target.value as string)
                }
            />

            <BarGraph scores={scores} />
        </div>
    );
}
