import { Container, Grid, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Score } from "../types";

interface BarGraphProps {
    scores: Score[];
}

export function BarGraph({ scores }: BarGraphProps) {
    const initialData = {
        labels: scores.map((score) => score.Name),
        datasets: [
            {
                label: "Score",
                data: scores.map((score) => score.Total),
                backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                borderColor: ["rgba(255, 99, 132, 1)"],
                borderWidth: 1,
            },
        ],
    };

    const [contests, setContests] = useState<string[]>([]);
    const [contest, setContest] = useState("");
    const [filteredScores, setFilteredScores] = useState<Score[]>([]);
    const [chartData, setChartData] = useState(initialData);
    const [year, setYear] = useState("");
    const [years, setYears] = useState<string[]>([]);

    useEffect(() => {
        const filteredScores = scores.filter((s) => s.Contest === contest);
        const uniqueYears = Array.from(
            new Set(filteredScores.map((score) => score.Year))
        );

        setYears(uniqueYears);
        if (uniqueYears.length > 0) setYear(uniqueYears[0]);
    }, [contest, scores]);

    useEffect(() => {
        const uniqueContests = Array.from(
            new Set(scores.map((score) => score.Contest))
        );

        const uniqueYears = Array.from(
            new Set(scores.map((score) => score.Year))
        );

        setContests(uniqueContests);
        setYears(uniqueYears);

        if (uniqueContests.length > 0) setContest(uniqueContests[0]);
    }, [scores]);

    useEffect(() => {
        const fs = scores.filter(
            (s) => s.Contest === contest && s.Year === year
        );

        setFilteredScores(fs);

        setChartData({
            labels: fs.map((score) => score.Name),
            datasets: [
                {
                    label: "Score",
                    data: fs.map((score) => score.Total),
                    backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                    borderColor: ["rgba(255, 99, 132, 1)"],
                    borderWidth: 1,
                },
            ],
        });
    }, [contest, scores, year]);

    const afterTitle = (tooltipItems: Array<any>) => {
        const score = filteredScores[tooltipItems[0].dataIndex];

        return score.Song;
    };

    const footer = (tooltipItems: Array<any>) => {
        const score = filteredScores[tooltipItems[0].dataIndex];

        return `Music: ${score.Mus}\nPresentation: ${score.Pres}\nSinging: ${score.Sing}`;
    };

    const options = {
        scales: { y: { beginAtZero: true, max: 100 } },
        plugins: {
            tooltip: {
                callbacks: {
                    afterTitle: afterTitle,
                    footer: footer,
                },
            },
        },
    };

    return (
        <Container style={{ marginTop: "5rem" }}>
            <Grid container spacing={2}>
                <Grid item>
                    <Select
                        value={contest}
                        onChange={(e) => setContest(e.target.value as string)}
                        displayEmpty
                    >
                        {contests.map((contest, i) => (
                            <MenuItem key={i} value={contest}>
                                {contest}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item>
                    <Select
                        value={year}
                        onChange={(e) => setYear(e.target.value as string)}
                        displayEmpty
                    >
                        {years.map((y) => (
                            <MenuItem key={y} value={y}>
                                {y}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            <Bar options={options} data={chartData} />
        </Container>
    );
}
